<template>
  <div>
    <!-- TODO: Reveal in dev env -->
    <div class="columns">
      <div
        v-if="false"
        class="column is-one-third"
      >
        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <div class="content">
                <h2 class="title has-text-grey">
                  <b-icon icon="trophy" />
                  Stats
                </h2>
              </div>
            </div>
          </div>
        </div>

        <hr>

        <div class="media has-background-primary">
          <figure class="media-left" />
          <div class="media-content">
            <div class="content">
              <p class="has-text-white is-capitalized">
                total invoices
                <br>
                <strong class="has-text-white"> Not available </strong>
              </p>
            </div>
          </div>
        </div>

        <hr>

        <router-link to="/opportunities">
          <div class="media">
            <div class="media-left">
              <div class="content">
                <h3 class="subtitle has-text-grey">
                  {{ profile.opportunitiesCount }}
                </h3>
              </div>
            </div>

            <div class="media-content">
              <div class="content">
                <p>
                  <strong class="has-text-primary">
                    {{ profile.opportunitiesCount }} Opportunities Created
                  </strong>
                  <br>
                  Last Opportunity:
                  {{
                    profile.lastOpportunity && profile.lastOpportunity.createdAt
                      ? $options.filters.date(profile.lastOpportunity.createdAt)
                      : null
                  }}
                </p>
              </div>
            </div>
          </div>
        </router-link>

        <hr>

        <router-link to="/leads">
          <div class="media">
            <div class="media-left">
              <div class="content">
                <h3 class="subtitle has-text-grey">
                  {{ profile.leadsCount }}
                </h3>
              </div>
            </div>

            <div class="media-content">
              <div class="content">
                <p>
                  <strong class="has-text-primary">Leads Created</strong>
                  <br>
                  Last Lead:
                  {{
                    profile.lastLead && profile.lastLead.createdAt
                      ? $options.filters.date(profile.lastLead.createdAt)
                      : ''
                  }}
                </p>
              </div>
            </div>
          </div>
        </router-link>

        <hr>
      </div>

      <div
        v-if="false"
        class="column"
      >
        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <div class="content">
                <h2 class="title has-text-grey">
                  <b-icon icon="flag-checkered" />
                  Sales Goals
                </h2>
              </div>
            </div>
          </div>

          <div
            v-if="hasRole('ROLE_BUSINESS_ADMIN')"
            class="level-right"
          >
            <div class="level-item">
              <b-button
                icon-right="edit"
                type="is-text"
                class="has-text-grey"
                @click="$router.push(`/edit-user/${userId}`)"
              />
            </div>
          </div>
        </div>

        <hr>

        <template v-if="goal.recurrenceIntervalId">
          <template v-if="goal.recurrenceIntervalId === 'monthly'">
            <div class="media">
              <div class="media-left">
                <div class="content">
                  <h3 class="subtitle has-text-grey">
                    Amount
                  </h3>
                </div>
              </div>

              <div class="media-content">
                <div class="content">
                  <p>
                    <strong class="has-text-primary">
                      {{ goal.amount | currency }}
                    </strong>
                    <br>
                    current: 0%
                  </p>
                </div>
              </div>
            </div>
          </template>

          <template v-else>
            <div class="media">
              <div class="media-left">
                <div class="content">
                  <h4 class="subtitle has-text-grey">
                    Q1
                  </h4>
                </div>
              </div>

              <div class="media-content">
                <div class="content">
                  <p>
                    <strong class="has-text-primary">
                      {{ goal.quarterly.q1 | currency }}
                    </strong>
                    <br>
                    current: 0%
                  </p>
                </div>
              </div>
            </div>

            <div class="media">
              <div class="media-left">
                <div class="content">
                  <h4 class="subtitle has-text-grey">
                    Q2
                  </h4>
                </div>
              </div>

              <div class="media-content">
                <div class="content">
                  <p>
                    <strong class="has-text-primary">
                      {{ goal.quarterly.q2 | currency }}
                    </strong>
                    <br>
                    current: 0%
                  </p>
                </div>
              </div>
            </div>

            <div class="media">
              <div class="media-left">
                <div class="content">
                  <h4 class="subtitle has-text-grey">
                    Q3
                  </h4>
                </div>
              </div>

              <div class="media-content">
                <div class="content">
                  <p>
                    <strong class="has-text-primary">
                      {{ goal.quarterly.q3 | currency }}
                    </strong>
                    <br>
                    current: 0%
                  </p>
                </div>
              </div>
            </div>

            <div class="media">
              <div class="media-left">
                <div class="content">
                  <h4 class="subtitle has-text-grey">
                    Q4
                  </h4>
                </div>
              </div>

              <div class="media-content">
                <div class="content">
                  <p>
                    <strong class="has-text-primary">
                      {{ goal.quarterly.q4 | currency }}
                    </strong>
                    <br>
                    current: 0%
                  </p>
                </div>
              </div>
            </div>
          </template>
        </template>

        <template v-else>
          <div class="column is-half">
            <figure class="image is-16by9">
              <img
                src="@/assets/images/goal.svg"
                alt="No goal"
              >
            </figure>
            <div class="level">
              <div class="level-item has-text-centered">
                <b-button
                  type="is-primary"
                  size="is-small"
                  aria-label="no goal assigned"
                >
                  no goal assigned
                </b-button>
              </div>
            </div>
          </div>
        </template>
      </div>

      <div
        v-if="false"
        class="column"
      >
        <activities-tabs @view-more="$router.push('/activities')" />
      </div>
    </div>

    <drilldowns
      :active-accounts-count="profile.activeAccountsCount"
      :contacts-count="profile.contactsCount"
      :leads-count="profile.leadsCount"
      :opportunities-count="profile.opportunitiesCount"
      :open-activities-count="profile.openActivitiesCount"
    />

    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <div class="content">
            <router-link to="/accounts">
              <h3 class="title has-text-info">
                Recent Accounts
              </h3>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="columns is-multiline">
      <template v-if="profile.recentAccounts && profile.recentAccounts.length > 0">
        <div
          v-for="(account, accountIndex) in profile.recentAccounts"
          :key="accountIndex"
          class="column is-half"
        >
          <favourite-accounts-card :account="account" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { Drilldowns, FavouriteAccountsCard } from '@/components/Shared';
import { ActivitiesTabs } from '@/components/Activities';

export default {
  name: 'ProfileOverviewTab',

  components: {
    Drilldowns,
    FavouriteAccountsCard,
    ActivitiesTabs,
  },

  data: () => ({
    userId: localStorage.getItem('user_id'),
    fullName: localStorage.getItem('full_name'),
  }),

  computed: {
    ...mapGetters({
      goal: 'Users/getGoal',
      profile: 'Users/getProfile',
    }),
  },

  destroyed() {
    this.$store.commit('Users/CLEAR_USER');
    this.$store.commit('Users/CLEAR_GOAL');
  },

  async created() {
    try {
      await this.fetchGoal(localStorage.getItem('user_id'));
      await this.fetchProfile();
    } catch (error) {
      console.error(error);
    }
  },

  methods: {
    ...mapActions({
      fetchGoal: 'Users/fetchGoal',
      fetchProfile: 'Users/fetchProfile',
    }),
  },
};
</script>

<style></style>
